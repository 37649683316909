// import { nameofFactory } from '@common/util/nameofFactory';
import * as yup from 'yup';

import {
  COMMON_ERR_MESSAGES,
  emailValidation,
  requiredValidation,
} from './common';

export const CLIENT_FORM = {
  CITY: 'city',
  SERVICE_TYPE: 'serviceType',
  PROBLEM_TYPE: 'problemType',
  SUBPROBLEM_TYPE: 'subProblemType',
  PRICING: 'pricing',
  DATE_AND_TIME: 'dateAndTime',
  TITLE: 'title',
  DESCRIPTION: 'description',
  IMAGES: 'images',
  VIDEOS: 'videos',
  FULL_NAME: 'fullName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  ADDRESS: 'address',
};

export const flowSchema = yup.object().shape({
  [CLIENT_FORM.CITY]: requiredValidation(CLIENT_FORM.CITY),
  [CLIENT_FORM.SERVICE_TYPE]: requiredValidation(CLIENT_FORM.SERVICE_TYPE),
  [CLIENT_FORM.PROBLEM_TYPE]: requiredValidation(CLIENT_FORM.PROBLEM_TYPE),
  [CLIENT_FORM.DATE_AND_TIME]: yup
    .array()
    .min(1, 'Select at least one date and time slot')
    .required(),
  [CLIENT_FORM.TITLE]: requiredValidation(CLIENT_FORM.TITLE),
  [CLIENT_FORM.DESCRIPTION]: requiredValidation(CLIENT_FORM.DESCRIPTION),
  [CLIENT_FORM.FULL_NAME]: requiredValidation('Full Name'),
  [CLIENT_FORM.EMAIL]: emailValidation,

  [CLIENT_FORM.PHONE_NUMBER]: requiredValidation('Contact Number'),
  [CLIENT_FORM.ADDRESS]: requiredValidation('Address'),
});

export const partialJobUpdateFlowSchema = yup.object().shape({
  [CLIENT_FORM.CITY]: requiredValidation(CLIENT_FORM.CITY),
  [CLIENT_FORM.SERVICE_TYPE]: requiredValidation(CLIENT_FORM.SERVICE_TYPE),
  [CLIENT_FORM.PROBLEM_TYPE]: requiredValidation(CLIENT_FORM.PROBLEM_TYPE),
  [CLIENT_FORM.EMAIL]: yup
    .string()
    .email(COMMON_ERR_MESSAGES.INVALID_EMAIL_FORMAT),
});
