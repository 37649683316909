import { extractFiles } from 'extract-files';

export function graphqlFetchOptions(query: string, variables: any) {
  const fetchOptions: any = {
    method: 'POST',
    headers: { Accept: 'application/json' },
  };
  const operation = {
    query,
    variables,
  };
  const { clone, files } = extractFiles(operation);
  const operationJSON = JSON.stringify(clone);
  try {
    if (files.size) {
      const form = new FormData();
      form.append('operations', operationJSON);

      const map: any = {};
      let i = 0;
      files.forEach((paths: any) => {
        map[++i] = paths;
      });
      form.append('map', JSON.stringify(map));

      i = 0;
      files.forEach((_: any, file: any) => {
        form.append(`${++i}`, file);
      });
      fetchOptions.body = form;
    } else {
      fetchOptions.headers['Content-Type'] = 'application/json';
      fetchOptions.body = operationJSON;
    }
  } catch (e) {
    console.log(e);
  }

  return fetchOptions;
}

// source code for graphqlFetchOptions
// https://github.com/jaydenseric/graphql-react/blob/1b1234de5de46b7a0029903a1446dcc061f37d09/src/universal/graphqlFetchOptions.mjs

// https://github.com/jaydenseric/graphql-react/blob/1b1234de5de46b7a0029903a1446dcc061f37d09/src/test/graphqlFetchOptions.mjs
