import { graphqlFetchOptions } from './fetchOptions';

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
): (() => Promise<TData>) => {
  return async () => {
    try {
      const { headers, ...fetchOptions } = graphqlFetchOptions(
        query,
        variables,
      );
      const promise = fetch(process.env.NEXT_PUBLIC_SERVER_URL as string, {
        headers: {
          ...headers,
        },
        credentials: 'include',
        ...fetchOptions,
      });

      const res = await promise;
      if (!res.ok) {
        throw new Error(
          'Network Error occured while fetching the data. Please try again later' +
            res.status +
            res.statusText,
        );
      } else {
        const json = await res.json();

        if (json.errors) {
          // const { message } = json.errors[0] || 'Error..';
          throw new Error(JSON.stringify(json.errors[0]));
          // return json.errors[0];
        }
        return json.data;
      }
    } catch (e) {
      throw new Error(
        'Some error occured while fetching the data. Please try again later' +
          e,
      );
    }
  };
};
