import * as yup from 'yup';

export const COMMON_VALIDATIONS = {
  EMAIL_MIN: 5,
  EMAIL_MAX: 255,
  USERNAME_MIN: 4,
  USERNAME_MAX: 25,
  PASSWORD_MIN: 8,
  PASSWORD_MAX: 25,
};

export const COMMON_ERR_MESSAGES = {
  FORGOT_PASSWORD_EXPIRED_KEY: 'Forgot link has expired',
  INVALID_CREDENTIALS: 'invalid credentials',
  CONFIRM_EMAIL: 'please confirm your email',
  FORGOT_PASSWORD_ACCOUNT_LOCKED: 'account is locked',
  DUPLICATE_EMAIL: 'Email already exists',
  LOGIN_OR_REGISTER: 'Please Login or Register',
  INVALID_EMAIL_FORMAT: 'Email format not valid',
  EMAIL_NOT_VALID: `Email should be between ${COMMON_VALIDATIONS.EMAIL_MIN} and 
		${COMMON_VALIDATIONS.EMAIL_MAX} characters`,
  PASSWORD_NOT_VALID: `Password should be between ${COMMON_VALIDATIONS.PASSWORD_MIN} and 
    ${COMMON_VALIDATIONS.PASSWORD_MAX} characters`,
  PASSWORD_MIN: `${COMMON_VALIDATIONS.PASSWORD_MIN} characters Minimum`,
  PASSWORD_MAX: `${COMMON_VALIDATIONS.PASSWORD_MAX} characters Maximum`,
  PASSWORD_UPPERCASE: 'One uppercase character',
};

export const PASSWORD_RULES = [
  {
    rule: new RegExp(`^.{${COMMON_VALIDATIONS.PASSWORD_MIN},}$`),
    message: `${COMMON_VALIDATIONS.PASSWORD_MIN} characters Minimum`,
  },
  {
    rule: new RegExp(`^.{0,${COMMON_VALIDATIONS.PASSWORD_MAX}}$`),
    message: `${COMMON_VALIDATIONS.PASSWORD_MAX} characters Maximum`,
  },
  {
    rule: new RegExp(/^\w*[A-Z]+\w*$/),
    message: 'One uppercase character',
  },
];

export const USERNAME_RULES = [
  {
    rule: new RegExp(`^.{${COMMON_VALIDATIONS.USERNAME_MIN},}$`),
    message: `${COMMON_VALIDATIONS.USERNAME_MIN} characters Minimum`,
  },
  {
    rule: new RegExp(`^.{0,${COMMON_VALIDATIONS.USERNAME_MAX}}$`),
    message: `${COMMON_VALIDATIONS.USERNAME_MAX} characters Maximum`,
  },
  {
    rule: new RegExp(/^\S*$/),
    message: 'No space allowed',
  },
  // {
  //   rule: new RegExp(/^[\w._\s-]*$/),
  //   message: 'No special characters allowed (except dot, hypen and underscore)'
  // }
];

export const emailValidation = yup
  .string()
  .required('Email Address is required')
  .email(COMMON_ERR_MESSAGES.INVALID_EMAIL_FORMAT);

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .matches(USERNAME_RULES[0].rule, USERNAME_RULES[0].message)
  .matches(USERNAME_RULES[1].rule, USERNAME_RULES[1].message)
  .matches(USERNAME_RULES[2].rule, USERNAME_RULES[2].message);
// .matches(USERNAME_RULES[3].rule, USERNAME_RULES[3].message);

export const objectValidation = (name: string) =>
  yup.object().nullable().required(`${name} is a required field`);

export const requiredValidation = (name: string) =>
  yup.string().required(`${name} is a required field`);

export const numberValidation = (name: string) =>
  yup
    .number()
    .typeError(`${name} is a Number field`)
    .required(`${name} is a required field`);

export const dateValidation = (name: string) =>
  yup.date().required(`${name} is a required field`);
